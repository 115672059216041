import styles from './FlexiTile.module.scss';

const isUndefined = value => typeof value === 'undefined';

const defaultFontColor = '#FFFFFF';

function setTextPosition(textPosition) {
  let textPositionClassNamesString = '';
  if (textPosition === 0) 
    textPositionClassNamesString = styles.t_l;
  if (textPosition === 1) 
    textPositionClassNamesString = styles.t_c;
  if (textPosition === 2) 
    textPositionClassNamesString = styles.t_r;
  if (textPosition === 3) 
    textPositionClassNamesString = styles.m_l;
  if (textPosition === 4) 
    textPositionClassNamesString = styles.m_c;
  if (textPosition === 5) 
    textPositionClassNamesString = styles.m_r;
  if (textPosition === 6) 
    textPositionClassNamesString = styles.b_l;
  if (textPosition === 7) 
    textPositionClassNamesString = styles.b_c;
  if (textPosition === 8) 
    textPositionClassNamesString = styles.b_r;

    return textPositionClassNamesString;
}
export { isUndefined, setTextPosition, defaultFontColor };
